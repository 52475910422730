import React from "react"
import { Row, Col, Card, CardBody, CardTitle, CardText } from "reactstrap"

class CoreValues extends React.Component {
  render() {
    const {title, text} = this.props
    return <Row className="mt-4">
    <Col xl={{ size: 10, offset: 1 }} className="mb-1">
      <Card className="py-2">
        <CardBody>
          <CardTitle className="font-weight-bold">{title}</CardTitle>
          <CardText>{text}
          </CardText>
        </CardBody>
      </Card>
    </Col>
  </Row>
  }
}
export default CoreValues
