import React from "react"
import HelmetTitle from "../components/utils"
import { Container, Row, Col } from "reactstrap"
import CoreValues from "../components/About/CoreValues"
import Layout from "../components/Layout"
import "../scss/modules/_m-hero-about-us.scss"

const coreValuesData = [
  {
    title: "Positive Impact",
    text:
      "Foretheta strives to improve the people and environment that it interacts with. We want to contribute to the \
      well-being of the communities we touch. We believe we can do that by teaching the skills and knowledge that we \
      have acquired. We do this by giving back through writing, speaking, and open-sourcing our code."
  },
  {
    title: "Raise The Bar",
    text:
      "With supporting text below as a natural lead-in to additional content. Foretheta is committed to constantly \
      raising the bar in every area that we work in. We strive to increase our technical expertise. We work hard to \
      constantly improve our craft and skills. We strive to improve the quality of the products we ship."
  },
  {
    title: "Customer Success",
    text:
      "Foretheta is dedicated to our customer's success. We are happy when our customers are successful. This means \
      that we will always be honest and upfront with our feedback. We will strive to do the right thing for our \
      customers, even if it means scaling down expectations. We want our customers to validate their ideas in small \
      chunks, validating their ideas at each stage."
  }
]

const AboutPage = () => {
  return (
    <Layout>
      <div className="about mb-6">
        <HelmetTitle title="About" />
        <div className="about-banner pt-6 pb-6 mb-4 text-white text-justify">
          <Container fluid>
            <Row>
              <Col xl={{ size: 10, offset: 1 }}>
                <h1 className="mb-5 font-weight-bold">About us</h1>
                <h3 className="mb-5 text-justify">
                  We're a small team of passionate and experienced software professionals. We give all of our clients
                  personal attention and are proud that 100% of our clients are references. We take our vision from
                  concept to reality and help your company grow through the process.
                </h3>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="core-values" fluid>
          <Row>
            <Col xl={{ size: 10, offset: 1 }}>
              <p className="header font-weight-bold">Our Core Values</p>
              <p className="title">
                We strive to create an environment where makers can create opportunities to drive a new era of
                development, growth and productivity. Here’s what that means to us.
              </p>
            </Col>
          </Row>
          {coreValuesData.map(value => {
            const { title, text } = value
            return <CoreValues key={title} title={title} text={text} />
          })}
        </Container>
      </div>
    </Layout>
  )
}

export default AboutPage
